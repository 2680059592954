import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import Home from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
    //{
    //    path: '/',
    //    name: 'Home',
    //    component: Home,
    //},
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue'),
    },
    //{
    //    path: '/register',
    //    name: 'register',
    //    component: () => import('../views/Register.vue'),
    //},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')
    if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
        next('/')
    } else {
        if (loggedIn && to.path == '/') {
            next('/dashboard')
        } else if (!loggedIn && to.path == '/dashboard') {
            next('/')
        }
    }
    next()
})

export default router
