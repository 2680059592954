<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" dark class="mb-2" v-on:click="on = newEvent()">Nová událost</v-btn>
                    </v-toolbar>
                    <v-skeleton-loader v-if="firstLoading" :loading="isLoading" class="mx-auto" type="table"></v-skeleton-loader>
                    <v-data-table
                        :headers="headers"
                        :items="data"
                        sort-by="name"
                        class="elevation-0"
                        @click:row="selectRow"
                        dense
                        :loading="isLoading"
                        v-show="!firstLoading"
                        fixed-header
                        height="calc(100vh - 245px)"
                        :footer-props="{
                            'items-per-page-text': 'Počet řádků na stránku',
                        }"
                    >
                        <template v-slot:top>
                            <!--<v-text-field v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>-->
                            <!--<v-spacer></v-spacer>-->
                            <v-dialog v-model="dialogDetail" max-width="700px" persistent @keydown.esc="close">
                                <!--<template v-slot:activator="{ on }">
                                        <v-btn color="primary" dark class="mb-2" v-on:click="on = newShipment()">New Shipment</v-btn>
                                    </template>-->
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                        <v-spacer></v-spacer>
                                        <span class="headline">{{ editedItem.id }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="userForm" @submit.prevent="save" id="user-form" v-model="formValidity">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="editedItem.name"
                                                            label="Jméno/SPZ"
                                                            :rules="requiredRule"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-menu
                                                            v-model="menu"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="290px"
                                                            min-width="200px"
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    v-model="computedDateFromFormatted"
                                                                    label="Datum od"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="dateFrom"
                                                                no-title
                                                                @input="menu = false"
                                                                :first-day-of-week="1"
                                                                locale="cs-CZ"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-menu
                                                            v-model="menu2"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            max-width="290px"
                                                            min-width="200px"
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    v-model="computedDateToFormatted"
                                                                    label="Datum do"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="dateTo"
                                                                no-title
                                                                @input="menu2 = false"
                                                                :first-day-of-week="1"
                                                                locale="cs-CZ"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="editedItem.remark"
                                                            label="Poznámka"
                                                            type="text"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Zrušit</v-btn>
                                        <v-btn color="blue darken-1" text @click="save" :disabled="!formValidity">Uložit</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                        </template>
                        <!--<template v-if="!isLoading && dialog" v-slot:item.role_id="{ item }">
                            <span>{{ getRoleName(item.role_id) }}</span>
                        </template>-->
                        <!--<template v-if="!isLoading" v-slot:item.role_id="{ item }">
                            <span>{{ getRoleName(item.role_id) }}</span>
                        </template>-->
                    </v-data-table>
                </v-card></v-col
            ></v-row
        >

        <!--</v-card-text>-->
    </v-container>
</template>

<script>
import MySqlServices from '../Services/MySqlServices'
import moment from 'moment'
//import DashboardFilter from '@/components/DashboardFilter.vue'

//import DashboardCharts from '@/components/DashboardCharts.vue'
//import SvgIcon from '@jamescoyle/vue-icon'
//import { mdiTruckCheckOutline } from '@mdi/js'

export default {
    name: 'SettingsEvents',
    //components: { DashboardFilter, DashboardCharts },
    //components: { SvgIcon },
    props: {
        loadData: { type: Boolean, default: false },
    },
    data() {
        return {
            dialogDetail: false,
            currentItem: '',
            timeoutId: null,
            menu: false,
            menu2: false,
            dateFrom: moment().add(0, 'd').format('YYYY-MM-DD'),
            dateTo: moment().add(7, 'd').format('YYYY-MM-DD'),
            search: '',
            filterWhere: { where: '' },
            activeTab: 0,
            headers: [
                { text: 'Jméno/SPZ', value: 'name', sortable: false, class: 'blue lighten-5' },
                { text: 'Datum od', value: 'date_from', sortable: false, class: 'blue lighten-5' },
                { text: 'Datum do', value: 'date_to', sortable: false, class: 'blue lighten-5' },
                { text: 'Poznámka', value: 'remark', sortable: false, class: 'blue lighten-5' },
                { text: 'Akce', value: 'actions', sortable: false, class: 'blue lighten-5' },
            ],
            data: [],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: '',
                date_from: '',
                date_to: '',
                remark: '',
            },
            defaultItem: {
                id: null,
                name: '',
                date_from: moment().format('YYYY-MM-DD'),
                date_to: moment().add(7, 'd').format('YYYY-MM-DD'),
                remark: '',
            },
            requiredRule: [(value) => !!value || 'Povinné pole'],
            emailRules: [
                (value) => value.indexOf('@') !== 0 || 'Email musí mít jméno.',
                (value) => value.includes('@') || 'Email musí obsahovat @ symbol.',
                (value) => value.lastIndexOf('.') > value.indexOf('@') || 'Email musí obsahovat platnou doménu.',
                (value) => value.includes('.') || 'Email musí obsahovat tečku před doménovou příponou.',
                (value) => value.lastIndexOf('.') <= value.length - 3 || 'Email musí obsahovat platnou doménovou příponu.',
            ],
            formValidity: false,
            isLoading: false,
            loadcharts: false,
            firstLoading: true,
            //roleItems: [],
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nová událost' : 'Upravit událost'
        },
        computedDateFromFormatted() {
            return moment(new Date(this.editedItem.date_from).toISOString()).format('DD.MM.YYYY')
        },
        computedDateToFormatted() {
            return moment(new Date(this.editedItem.date_to).toISOString()).format('DD.MM.YYYY')
        },
    },

    watch: {
        loadData: {
            handler: function () {
                //console.log('triggered ' + this.loadcharts)
                if (this.loadData) {
                    //console.log('SettingsEvents initialized')
                    this.initialize()
                }
            },
            immediate: true,
        },
        dialogDetail(val) {
            if (val) {
                if (this.$refs.userForm) {
                    this.$refs.userForm.resetValidation()
                }
            } else {
                this.close()
            }

            //val || this.close()
        },
        dateFrom(val) {
            this.editedItem.date_from = moment(String(val)).format('YYYY-MM-DD')
        },
        dateTo(val) {
            this.editedItem.date_to = moment(String(val)).format('YYYY-MM-DD')
        },
    },
    created() {},
    mounted() {
        //this.initialize()
        //console.log('SettingsUsers mounted')
    },

    methods: {
        //required(value) {
        //    if (value instanceof Array && value.length == 0) {
        //        return 'Povinné pole'
        //    }
        //    return !!value || 'Povinné pole'
        //},

        initialize() {
            MySqlServices.callEvents({ params: this.filterWhere }).then(({ data }) => {
                this.data = data
                this.isLoading = false
                this.firstLoading = false
            })

            //MySqlServices.callUsersRoles({ params: '' }).then(({ data }) => {
            //    this.roleItems = data
            //})

            this.$emit('dataInitialized')
        },

        editItem(item) {
            this.editedIndex = this.data.indexOf(item)
            this.dateFrom = item.date_from
            this.dateTo = item.date_to
            this.editedItem = Object.assign(
                {},
                { id: item.id, name: item.name, date_from: item.date_from, date_to: item.date_to, remark: item.remark }
            )

            this.dialogDetail = true
        },

        deleteItem(item) {
            const index = this.data.indexOf(item)

            if (confirm("'Přejete si záznam smazat?'")) {
                //axios.delete('https://luwi-nodejs-server.herokuapp.com/shipments', { data: { id: item.id } }).then(({ data }) => {
                //    //this.shipments = data
                //    //this.isLoading = false
                //    if (data.result == 'ok') {
                //        this.shipments.splice(index, 1)
                //    }
                //})
                MySqlServices.callDeleteEvents({ data: { id: item.id } }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        this.data.splice(index, 1)
                    }
                })
            }
        },

        close() {
            this.dialogDetail = false
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            }, 300) //or 900 if closing happens before saved data confirmation are returned
        },

        save() {
            if (this.editedIndex > -1) {
                MySqlServices.callUpdateEvents({ data: this.editedItem }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        Object.assign(this.data[this.editedIndex], this.editedItem)
                    }
                    this.close()
                })
            } else {
                MySqlServices.callInsertEvents({ data: this.editedItem }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        this.editedItem.id = data.insertid
                        this.data.push(this.editedItem)

                        Object.assign(this.data[this.data.length - 1], this.editedItem)
                    }
                    this.close()
                })
            }
            //this.close()
        },
        //getRoleName(role_id) {
        //    //musel jsem oRole nadefinovat, protoze bez toho to neznalo property "role" pri returnu
        //    var oRole = { id: '', role: '' }
        //    var oFoundRole = this.roleItems.find((o) => o.id === role_id)
        //    Object.assign(oRole, oFoundRole)
        //    //let oRole = this.roleItems.findIndex((o) => o.id === role_id)

        //    return oRole.role
        //},
        selectRow(event) {
            if (!this.timeoutId) {
                this.timeoutId = setTimeout(() => {
                    //this.snackbar = true
                    this.currentItem = event.ship_no
                    this.timeoutId = null
                }, 300) //tolerance in ms
            } else {
                clearTimeout(this.timeoutId)
                this.timeoutId = null
                // double click
                this.editItem(event)
            }
        },

        formatDate(value) {
            return moment(String(value), 'YYYY-MM-DD').format('DD.MM.YYYY')
        },
        //setFilter(event) {
        //    this.activeTab = event.activeTab
        //    this.filterWhere.where = event.filterWhere
        //    this.isLoading = true
        //    this.initialize()
        //},
        newEvent() {
            //console.error('detail initiated')
            this.editedItem = Object.assign({}, this.defaultItem)
            this.dialogDetail = true
        },
    },
}
</script>
