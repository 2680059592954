<template>
    <v-app>
        <v-app-bar app color="primary" dark height="50">
            <v-toolbar-title v-if="loggedIn">v2.5 - {{ userName }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="centered-text">
                {{ new Date().getFullYear() }} —
                <strong>ZACH Trans</strong>
            </div>
            <v-spacer></v-spacer>
            <!--<v-flex primary dark py-4 text-center red--text xs12>-->

            <!--</v-flex>-->
            <!--<v-btn v-for="link in links" :key="`${link.label}-footer-link`" text rounded :to="link.url">{{ link.label }}</v-btn>-->
            <!--<v-btn text rounded to="/">Home</v-btn>-->
            <!--<v-btn v-if="!loggedIn" text rounded to="/register">Register</v-btn>-->
            <v-btn v-if="!loggedIn" text rounded to="/login">Login</v-btn>
            <!--<v-btn v-else text rounded @click="logout">Logout</v-btn>-->
            <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
            <!--<v-btn v-if="loggedIn" text rounded to="/dashboard">Dashboard</v-btn>-->
            <v-menu v-if="loggedIn" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item v-if="userRoleId == 1" @click="openSettingsUsers()">
                        <v-list-item-icon>
                            <v-icon>mdi-cog-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Nastavení</v-list-item-title>
                    </v-list-item>
                    <!--<v-list-item @click="openSettingsUserRoles()">
                        <v-list-item-icon>
                            <v-icon>mdi-account-key-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Uživatelské role</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openSettingsStatuses()">
                        <v-list-item-icon>
                            <v-icon>mdi-list-status</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Statusy</v-list-item-title>
                    </v-list-item>-->
                    <v-divider horizontal></v-divider>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Odhlásit</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main>
            <router-view></router-view>
            <Settings v-model="showSettings" />
        </v-main>
        <v-footer app color="orange lighten-2" padless>
            <v-layout justify-center wrap @click="toggleEvents">
                <!--<v-btn v-for="link in links" :key="`${link.label}-footer-link`" color="white" text rounded class="my-2" :to="link.url">{{
                    link.label
                }}</v-btn>-->
                <v-flex orange lighten-2 py-4 text-center red--text xs12 style="pointer-events: none">
                    <!--{{ new Date().getFullYear() }} —
                    <strong>ZACH Trans</strong>-->
                    <div v-for="(eventItem, index) in eventItemsDisplayed" :key="index">
                        {{ eventItem.name + ' od ' + eventItem.date_from + ' do ' + eventItem.date_to + ': ' + eventItem.remark }}
                    </div>
                </v-flex>
                <!--<span style="direction: rtl">Text, který ubíhá zprava do leva Text, který ubíhá zprava do leva</span>-->
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script>
import { authComputed } from './store/helpers.js'
import Settings from '@/views/Settings'
import MySqlServices from './Services/MySqlServices'

export default {
    name: 'App',
    data() {
        return {
            //links: [
            //    { label: 'Home', url: '/' },
            //    { label: 'Login', url: '/login' }
            //    //{ label: 'Dashboard', url: '/dashboard' }
            //]
            showSettings: false,
            API_URL: process.env.VUE_APP_API_URL,
            eventItems: [],
            eventItemsDisplayed: [],
            displayAllEvents: true,
        }
    },
    components: { Settings },
    computed: {
        ...authComputed,
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        openSettingsUsers() {
            this.showSettings = true
        },
        //openSettingsUserRoles() {},
        //openSettingsStatuses() {},
        toggleEvents() {
            if (this.displayAllEvents) {
                this.eventItemsDisplayed = this.eventItems.slice(0, 2) // Zobrazit jen dva nejnovější
            } else {
                this.eventItemsDisplayed = this.eventItems // Zobrazit všechny
            }
            this.displayAllEvents = !this.displayAllEvents // Přepnout stav
        },
        readEvents() {
            MySqlServices.callEventsActual({ params: '' }).then(({ data }) => {
                this.eventItems = data
                if (this.displayAllEvents) {
                    this.eventItemsDisplayed = data
                } else {
                    this.eventItemsDisplayed = this.eventItems.slice(0, 2)
                }
            })
        },
    },
    mounted() {
        this.readEvents()
        setInterval(this.readEvents, 60000)
    },
}
</script>
