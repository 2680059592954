import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import MySqlServices from './Services/MySqlServices'
//import VueSocketIO from 'vue-socket.io'
//import io from 'socket.io-client'

//const socket = io('http://localhost:3000') // process.env.VUE_APP_API_URL

//Vue.use(
//    new VueSocketIO({
//        debug: true,
//        connection: socket,
//    })
//)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,

    created() {
        const userString = localStorage.getItem('user') // grab user data from local storage
        if (userString) {
            // check to see if there is indeed a user
            const userData = JSON.parse(userString) // parse user data into JSON
            this.$store.commit('SET_USER_DATA', userData) // restore user data with Vuex

            MySqlServices.callLog({ user_id: userData.id, action: 'logged', details: 'already logged' }).then(({ data }) => {
                if (data.result == 'ok') {
                    //console.error('main ok')
                }
            })
        }

        axios.interceptors.response.use(
            (response) => response, // simply return the response
            (error) => {
                if (error.response.status === 401) {
                    // if we catch a 401 error
                    this.$store.dispatch('logout') // force a log out
                }
                return Promise.reject(error) // reject the Promise, with the error as the reason
            }
        )
    },

    render: (h) => h(App),
}).$mount('#app')
