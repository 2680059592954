import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'
import MySqlServices from '../Services/MySqlServices'
//import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
    },
    mutations: {
        SET_USER_DATA(state, userData) {
            state.user = userData
            localStorage.setItem('user', JSON.stringify(userData))

            //axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
            MySqlServices.setAuthorizationHeader(`Bearer ${userData.token}`)
        },
        CLEAR_USER_DATA() {
            // state.user = null
            localStorage.removeItem('user')
            //axios.defaults.headers.common['Authorization'] = null
            //MySqlServices.setAuthorizationHeader(null)
            //location.reload()
            //router.push({ name: 'Home' })
            location.reload()
            //router.go(0)
        },
    },
    actions: {
        register({ commit }, credentials) {
            //return axios.post('https://luwi-nodejs-server.herokuapp.com/register', credentials).then(({ data }) => {
            //    commit('SET_USER_DATA', data)
            //})
            return MySqlServices.callRegister(credentials).then(({ data }) => {
                commit('SET_USER_DATA', data)
            })
        },
        login({ commit }, credentials) {
            return MySqlServices.callLogin(credentials).then(({ data }) => {
                commit('SET_USER_DATA', data)
            })
        },
        logout({ commit }) {
            commit('CLEAR_USER_DATA')
        },
    },
    getters: {
        loggedIn(state) {
            return !!state.user
        },
        userRoleId(state) {
            return state.user.role_id
        },
        userName(state) {
            return state.user === null ? '' : state.user.name
        },
        userId(state) {
            return state.user === null ? '' : state.user.id
        },
    },
})
