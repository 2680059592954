<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" dark class="mb-2" v-on:click="on = newUser()">Nový uživatel</v-btn>
                    </v-toolbar>
                    <v-skeleton-loader v-if="firstLoading" :loading="isLoading" class="mx-auto" type="table"></v-skeleton-loader>
                    <v-data-table
                        :headers="headers"
                        :items="data"
                        sort-by="name"
                        class="elevation-0"
                        @click:row="selectRow"
                        dense
                        :loading="isLoading"
                        v-show="!firstLoading"
                        fixed-header
                        height="calc(100vh - 245px)"
                        :footer-props="{
                            'items-per-page-text': 'Počet řádků na stránku',
                        }"
                    >
                        <template v-slot:top>
                            <!--<v-text-field v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>-->
                            <!--<v-spacer></v-spacer>-->
                            <v-dialog v-model="dialogDetail" max-width="700px" persistent @keydown.esc="close">
                                <!--<template v-slot:activator="{ on }">
                                        <v-btn color="primary" dark class="mb-2" v-on:click="on = newShipment()">New Shipment</v-btn>
                                    </template>-->
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{ formTitle }}</span>
                                        <v-spacer></v-spacer>
                                        <span class="headline">{{ editedItem.id }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="userForm" @submit.prevent="save" id="user-form" v-model="formValidity">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="editedItem.name"
                                                            label="Jméno"
                                                            :rules="requiredRule"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="editedItem.email"
                                                            label="E-mail"
                                                            :rules="emailRules"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-select
                                                            v-model="editedItem.role_id"
                                                            :items="roleItems"
                                                            label="Role *"
                                                            item-text="role"
                                                            item-value="id"
                                                            required
                                                            :rules="requiredRule"
                                                        ></v-select>
                                                        <!--<v-text-field v-model="editedItem.status_id" label="Status" readonly></v-text-field>-->
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="editedItem.password"
                                                            :type="showPassword ? 'text' : 'password'"
                                                            :label="editedIndex == -1 ? 'Heslo' : 'Resetovat heslo'"
                                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                            @click:append="showPassword = !showPassword"
                                                            :rules="passwordRule"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Zrušit</v-btn>
                                        <v-btn color="blue darken-1" text @click="save" :disabled="!formValidity">Uložit</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                        </template>
                        <!--<template v-if="!isLoading && dialog" v-slot:item.role_id="{ item }">
                            <span>{{ getRoleName(item.role_id) }}</span>
                        </template>-->
                        <template v-if="!isLoading" v-slot:item.role_id="{ item }">
                            <span>{{ getRoleName(item.role_id) }}</span>
                        </template>
                    </v-data-table>
                </v-card></v-col
            ></v-row
        >

        <!--</v-card-text>-->
    </v-container>
</template>

<script>
import MySqlServices from '../Services/MySqlServices'
import moment from 'moment'
//import DashboardFilter from '@/components/DashboardFilter.vue'

//import DashboardCharts from '@/components/DashboardCharts.vue'
//import SvgIcon from '@jamescoyle/vue-icon'
//import { mdiTruckCheckOutline } from '@mdi/js'

export default {
    name: 'Settings',
    //components: { DashboardFilter, DashboardCharts },
    //components: { SvgIcon },
    props: {
        loadData: { type: Boolean, default: false },
    },
    data() {
        return {
            showPassword: false,
            dialogDetail: false,
            currentItem: '',
            timeoutId: null,
            menu2: false,
            date: null,
            search: '',
            filterWhere: { where: '' },
            activeTab: 0,
            headers: [
                { text: 'Jméno', value: 'name', sortable: false, class: 'blue lighten-5' },
                { text: 'E-mail', value: 'email', sortable: false, class: 'blue lighten-5' },
                { text: 'Role', value: 'role_id', sortable: false, class: 'blue lighten-5' },
                { text: 'Akce', value: 'actions', sortable: false, class: 'blue lighten-5' },
            ],
            data: [],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: '',
                password: '',
                role_id: '',
                email: '',
            },
            defaultItem: {
                id: null,
                name: '',
                password: '',
                role_id: '',
                email: '',
            },
            requiredRule: [(value) => !!value || 'Povinné pole'],
            passwordRules: [
                (value) => !!value || 'Please enter a password',
                (value) => !!value || 'Please type password.',
                (value) => (value && /\d/.test(value)) || 'At least one digit',
                (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
                (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
                (value) => (value && value.length < 6) || 'minimum 6 characters',
            ],
            passwordRule: [(value) => !!value || this.editedIndex > -1 || 'Povinné pole'],
            emailRules: [
                (value) => value.indexOf('@') !== 0 || 'Email musí mít jméno.',
                (value) => value.includes('@') || 'Email musí obsahovat @ symbol.',
                (value) => value.lastIndexOf('.') > value.indexOf('@') || 'Email musí obsahovat platnou doménu.',
                (value) => value.includes('.') || 'Email musí obsahovat tečku před doménovou příponou.',
                (value) => value.lastIndexOf('.') <= value.length - 3 || 'Email musí obsahovat platnou doménovou příponu.',
            ],
            formValidity: false,
            isLoading: false,
            loadcharts: false,
            firstLoading: true,
            roleItems: [],
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nový uživatel' : 'Upravit uživatele'
        },
        computedDateFormatted() {
            return moment(new Date(this.editedItem.date_shipment).toISOString()).format('DD.MM.YYYY')
        },
    },

    watch: {
        loadData: {
            handler: function () {
                //console.log('triggered ' + this.loadcharts)
                if (this.loadData) {
                    //console.log('SettingsUsers initialized')
                    this.initialize()
                }
            },
            immediate: true,
        },
        dialogDetail(val) {
            if (val) {
                if (this.$refs.userForm) {
                    this.$refs.userForm.resetValidation()
                }
            } else {
                this.close()
            }

            //val || this.close()
        },
        date(val) {
            this.editedItem.date_creation = moment(String(val)).format('YYYY-MM-DD')
        },
    },
    created() {},
    mounted() {
        //this.initialize()
        //console.log('SettingsUsers mounted')
    },

    methods: {
        //required(value) {
        //    if (value instanceof Array && value.length == 0) {
        //        return 'Povinné pole'
        //    }
        //    return !!value || 'Povinné pole'
        //},

        initialize() {
            MySqlServices.callUsers({ params: this.filterWhere }).then(({ data }) => {
                this.data = data
                this.isLoading = false
                this.firstLoading = false
            })

            MySqlServices.callUsersRoles({ params: '' }).then(({ data }) => {
                this.roleItems = data
            })

            this.$emit('dataInitialized')
        },

        editItem(item) {
            this.editedIndex = this.data.indexOf(item)
            this.editedItem = Object.assign({}, { id: item.id, name: item.name, email: item.email, password: '', role_id: item.role_id })

            this.dialogDetail = true
        },

        deleteItem(item) {
            const index = this.data.indexOf(item)

            if (confirm("'Přejete si záznam smazat?'")) {
                //axios.delete('https://luwi-nodejs-server.herokuapp.com/shipments', { data: { id: item.id } }).then(({ data }) => {
                //    //this.shipments = data
                //    //this.isLoading = false
                //    if (data.result == 'ok') {
                //        this.shipments.splice(index, 1)
                //    }
                //})
                MySqlServices.callDeleteUsers({ data: { id: item.id } }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        this.data.splice(index, 1)
                    }
                })
            }
        },

        close() {
            this.dialogDetail = false
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            }, 300) //or 900 if closing happens before saved data confirmation are returned
        },

        save() {
            if (this.editedIndex > -1) {
                MySqlServices.callUpdateUsers({ data: this.editedItem }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        Object.assign(this.data[this.editedIndex], this.editedItem)
                    }
                    this.close()
                })
            } else {
                MySqlServices.callInsertUsers({ data: this.editedItem }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        this.editedItem.id = data.insertid
                        this.data.push(this.editedItem)

                        Object.assign(this.data[this.data.length - 1], this.editedItem)
                    }
                    this.close()
                })
            }
            //this.close()
        },
        getRoleName(role_id) {
            //musel jsem oRole nadefinovat, protoze bez toho to neznalo property "role" pri returnu
            var oRole = { id: '', role: '' }
            var oFoundRole = this.roleItems.find((o) => o.id === role_id)
            Object.assign(oRole, oFoundRole)
            //let oRole = this.roleItems.findIndex((o) => o.id === role_id)

            return oRole.role
        },
        selectRow(event) {
            if (!this.timeoutId) {
                this.timeoutId = setTimeout(() => {
                    //this.snackbar = true
                    this.currentItem = event.ship_no
                    this.timeoutId = null
                }, 300) //tolerance in ms
            } else {
                clearTimeout(this.timeoutId)
                this.timeoutId = null
                // double click
                this.editItem(event)
            }
        },

        formatDate(value) {
            return moment(String(value), 'YYYY-MM-DD').format('DD.MM.YYYY')
        },
        //setFilter(event) {
        //    this.activeTab = event.activeTab
        //    this.filterWhere.where = event.filterWhere
        //    this.isLoading = true
        //    this.initialize()
        //},
        newUser() {
            //console.error('detail initiated')
            this.editedItem = Object.assign({}, this.defaultItem)
            this.dialogDetail = true
        },
    },
}
</script>
