<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-top-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Nastavení</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <!--<v-card-text style="width: 700px; margin-top: 20px" class="text-center">-->
                <v-tabs background-color="white" v-model="activeTab" slider-color="primary" slider-size="4" @change="changeTab">
                    <v-tab>
                        <!--<v-icon left>mdi-account</v-icon>-->
                        Uživatelé
                    </v-tab>
                    <v-tab> Události </v-tab>

                    <v-tab-item style="margin-top: 20px">
                        <SettingsUsers :loadData="loadDataUsers" v-on:dataInitialized="loadDataUsers = false"
                    /></v-tab-item>

                    <v-tab-item style="margin-top: 20px">
                        <SettingsEvents :loadData="loadDataEvents" v-on:dataInitialized="loadDataEvents = false"
                    /></v-tab-item>
                </v-tabs>

                <!--</v-card-text>-->
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import MySqlServices from '../Services/MySqlServices'
import moment from 'moment'
import SettingsUsers from '@/components/SettingsUsers.vue'
import SettingsEvents from '@/components/SettingsEvents.vue'

//import DashboardCharts from '@/components/DashboardCharts.vue'
//import SvgIcon from '@jamescoyle/vue-icon'
//import { mdiTruckCheckOutline } from '@mdi/js'

export default {
    name: 'Settings',
    components: { SettingsUsers, SettingsEvents },
    //components: { SvgIcon },
    props: {
        value: Boolean,
    },
    data() {
        return {
            loadDataUsers: true,
            loadDataEvents: false,
            showPassword: false,
            dialogDetail: false,
            currentItem: '',
            timeoutId: null,
            menu2: false,
            date: null,
            search: '',
            filterWhere: { where: '' },
            activeTab: 0,
            headers: [
                { text: 'Jméno', value: 'name', sortable: false, class: 'blue lighten-5' },
                { text: 'E-mail', value: 'email', sortable: false, class: 'blue lighten-5' },
                { text: 'Role', value: 'role_id', sortable: false, class: 'blue lighten-5' },
                { text: 'Akce', value: 'actions', sortable: false, class: 'blue lighten-5' },
            ],
            data: [],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: '',
                password: '',
                role_id: '',
                email: '',
            },
            defaultItem: {
                id: null,
                name: '',
                password: '',
                role_id: '',
                email: '',
            },
            requiredRule: [(value) => !!value || 'Povinné pole'],
            passwordRules: [
                (value) => !!value || 'Please enter a password',
                (value) => !!value || 'Please type password.',
                (value) => (value && /\d/.test(value)) || 'At least one digit',
                (value) => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
                (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
                (value) => (value && value.length < 6) || 'minimum 6 characters',
            ],
            passwordRule: [(value) => !!value || this.editedIndex > -1 || 'Povinné pole'],
            emailRules: [
                (value) => value.indexOf('@') !== 0 || 'Email musí mít jméno.',
                (value) => value.includes('@') || 'Email musí obsahovat @ symbol.',
                (value) => value.lastIndexOf('.') > value.indexOf('@') || 'Email musí obsahovat platnou doménu.',
                (value) => value.includes('.') || 'Email musí obsahovat tečku před doménovou příponou.',
                (value) => value.lastIndexOf('.') <= value.length - 3 || 'Email musí obsahovat platnou doménovou příponu.',
            ],
            formValidity: false,
            isLoading: false,
            loadcharts: false,
            firstLoading: true,
            roleItems: [],
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        formTitle() {
            return this.editedIndex === -1 ? 'Nový uživatel' : 'Upravit uživatele'
        },
        computedDateFormatted() {
            return moment(new Date(this.editedItem.date_shipment).toISOString()).format('DD.MM.YYYY')
        },
    },

    watch: {
        dialogDetail(val) {
            if (val) {
                if (this.$refs.userForm) {
                    this.$refs.userForm.resetValidation()
                }
            } else {
                this.close()
            }

            //val || this.close()
        },
        dialog(val) {
            if (val) {
                //console.error('settings initiated')
                this.initialize()
                this.changeTab(this.activeTab)
            }

            //val || this.close()
        },
        date(val) {
            this.editedItem.date_creation = moment(String(val)).format('YYYY-MM-DD')
        },
    },
    created() {},
    mounted() {
        //console.error('create detail initiated')
        //this.initialize()
        //this.initializeCharts()
    },

    methods: {
        changeTab(tab) {
            if (tab == 0) {
                this.loadDataUsers = true
            } else if (tab == 1) {
                this.loadDataEvents = true
            }
        },
        required(value) {
            if (value instanceof Array && value.length == 0) {
                return 'Povinné pole'
            }
            return !!value || 'Povinné pole'
        },

        initialize() {
            MySqlServices.callUsers({ params: this.filterWhere }).then(({ data }) => {
                this.data = data
                this.isLoading = false
                this.firstLoading = false
            })

            MySqlServices.callUsersRoles({ params: '' }).then(({ data }) => {
                this.roleItems = data
            })

            //this.$refs.settingsUsersChild.initialize()
        },

        editItem(item) {
            this.editedIndex = this.data.indexOf(item)
            this.editedItem = Object.assign({}, { id: item.id, name: item.name, email: item.email, password: '', role_id: item.role_id })

            this.dialogDetail = true
        },

        deleteItem(item) {
            const index = this.data.indexOf(item)

            if (confirm("'Přejete si záznam smazat?'")) {
                //axios.delete('https://luwi-nodejs-server.herokuapp.com/shipments', { data: { id: item.id } }).then(({ data }) => {
                //    //this.shipments = data
                //    //this.isLoading = false
                //    if (data.result == 'ok') {
                //        this.shipments.splice(index, 1)
                //    }
                //})
                MySqlServices.callDeleteUsers({ data: { id: item.id } }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        this.data.splice(index, 1)
                    }
                })
            }
        },

        close() {
            this.dialogDetail = false
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            }, 300) //or 900 if closing happens before saved data confirmation are returned
        },

        save() {
            if (this.editedIndex > -1) {
                MySqlServices.callUpdateUsers({ data: this.editedItem }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        Object.assign(this.data[this.editedIndex], this.editedItem)
                    }
                    this.close()
                })
            } else {
                MySqlServices.callInsertUsers({ data: this.editedItem }).then(({ data }) => {
                    //this.shipments = data
                    //this.isLoading = false
                    if (data.result == 'ok') {
                        this.editedItem.id = data.insertid
                        this.data.push(this.editedItem)

                        Object.assign(this.data[this.data.length - 1], this.editedItem)
                    }
                    this.close()
                })
            }
            //this.close()
        },
        getRoleName(role_id) {
            //musel jsem oRole nadefinovat, protoze bez toho to neznalo property "role" pri returnu
            var oRole = { id: '', role: '' }
            var oFoundRole = this.roleItems.find((o) => o.id === role_id)
            Object.assign(oRole, oFoundRole)
            //let oRole = this.roleItems.findIndex((o) => o.id === role_id)

            return oRole.role
        },
        selectRow(event) {
            if (!this.timeoutId) {
                this.timeoutId = setTimeout(() => {
                    //this.snackbar = true
                    this.currentItem = event.ship_no
                    this.timeoutId = null
                }, 300) //tolerance in ms
            } else {
                clearTimeout(this.timeoutId)
                this.timeoutId = null
                // double click
                this.editItem(event)
            }
        },
        //formatDate(date) {
        //    if (!date) return null

        //    const [day, month, year] = date.split('/')
        //    return `${day}/${month}/${year}`
        //}
        //formatDate(value) {
        //    return moment(String(value), 'YYYY-MM-DD').format('DD.MM.YYYY')
        //},
        //setFilter(event) {
        //    this.activeTab = event.activeTab
        //    this.filterWhere.where = event.filterWhere
        //    this.isLoading = true
        //    this.initialize()
        //},
        //getSelected() {
        //    confirm(JSON.stringify(this.selected))
        //},
        //getColor(dgm) {
        //    //if (dgm == 1) return 'red'
        //    //else return 'transparent'
        //    return dgm
        //},
        newUser() {
            //console.error('detail initiated')
            this.editedItem = Object.assign({}, this.defaultItem)
            this.dialogDetail = true
        },

        //getIcon(itm) {
        //    switch (itm.status_icon) {
        //        case 'mdiTruckCheckOutline':
        //            return mdiTruckCheckOutline
        //    }
        //},
    },
}
</script>
